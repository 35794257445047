<!--
 * @Descripttion : 订单详情
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-09-08 10:26:38
 * @LastEditors: min
 * @LastEditTime: 2020-11-03 15:16:41
-->
<template>
  <div class="ml_detail">
    <a-avatar icon="rollback" size="large" class="ml_back_page" @click="goBack" />
    <orderDetail :orderObj="orderObj" v-if="orderObj" @onSave="onUploadSave" :isEdit="isEdit"></orderDetail>
  </div>
</template>
<script>
import { getOrderByOrderNumber } from "@/api/order";

import orderDetail from "@/components/component-order-detail/component-order-detaile";

export default {
  components: {
    orderDetail
  },
  data () {
    return {
      orderNumber: "",
      orderObj: null, //  订单详情
      isEdit:null, // 2--平台待审核
    };
  },
  mounted () {
    this.orderNumber = this.$route.query.orderno;
    this.isEdit = this.$route.query.isEdit;
    this.getOrderDetail();
  },
  methods: {
    goBack () {
      this.$router.back();
    },
    getOrderDetail () {
      const that = this;
      getOrderByOrderNumber(that.orderNumber).then(res => {
        that.orderObj = res.data;
      });
    },
    onUploadSave () {
      // this.getOrderDetail(); //办证详情
      this.$router.back(); //返回列表
    }
  }
};
</script>
<style lang="scss">
.ml_detail {
  padding: 0 10px 30px 10px;
  .ml_back_page {
    background-color: #1890ff;
    position: fixed;
    right: 30px;
    bottom: 60px;
    z-index: 99999;
    cursor: pointer;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
}
</style>